import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import NFC from "../assets/NFC";
import VectorUI from "../UI/Vector";
import About from "./About";
import Sots from "../UI/Sots";
import ContactsModal from "./Contacts";

const Content = () => {
  const [state, setState] = useState(0);
  const [media, setMedia] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  useEffect(() => {
    setTimeout(() => {
      setState(1);
    }, 1000);
    setTimeout(() => {
      setState(2);
    }, 3000);

    if (window.innerWidth < 600) {
      return setMedia(!media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box id={"vector"}>
      {state === 1 && (
        <Box
          className=" main-animation"
          position="fixed"
          top="0"
          left="0"
          w="100%"
          h="100vh"
          zIndex="100"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box className="main-animation__text">
            <NFC />
          </Box>
        </Box>
      )}

      <div className="grid-container">
        <VectorUI media={media} className={"odd"} />
        <VectorUI media={media} className={"even"} />

        <Box display={{ md: "none", base: "block" }}>
          <VectorUI media={media} className={"odd"} />
          <VectorUI media={media} className={"even"} />
        </Box>

        <Sots
          className={"odd"}
          idx1={6}
          sot1={
            <Box onClick={onToggle}>
              <Box className="aboutUs"></Box>
            </Box>
          }
          media={media}
          state={state}
        />

        <About onToggle={onToggle} isOpen={isOpen} />

        <VectorUI media={media} className={"even"} />
        <Sots
          className={"odd"}
          idx1={5}
          sot1={<Box className="roboBarista"></Box>}
          media={media}
          state={state}
          link1={"https://robo-barista.com/RoboBarista.pdf"}
        />
        <Sots
          className={"even"}
          idx1={media ? 5 : 6}
          sot1={<Box className="smartCard"></Box>}
          media={media}
          state={state}
          link1={"https://nfc-bc.com"}
        />
        <Sots
          className={"odd"}
          idx1={6}
          // idx2={5}
          sot1={<Box className="gidroGel"></Box>}
          // sot2={<Box className="RentACar"></Box>}
          media={media}
          state={state}
          link1={"https://www.gulfagro.co/gulfagro_presentation.pdf"}
          // link2={"https://nfc-gcc.com/RENTACARSMARTSYSTEM.pdf"}
        />

        <Sots
          className={"even"}
          idx1={media ? 5 : 6}
          sot1={<Box className="roboCarWash"></Box>}
          media={media}
          state={state}
          link1={"https://robo-car-wash.com"}
        />

        <Sots
          className={"odd"}
          idx1={5}
          sot1={<Box className="SwissMedical"></Box>}
          media={media}
          state={state}
          link1={
            "https://video.wixstatic.com/video/c430d9_996a43ebd3164b52bc5edfbb966c0079/1080p/mp4/file.mp4"
          }
        />
        <Sots
          className={"even"}
          idx1={media ? 5 : 6}
          sot1={<Box className="Restaurants"></Box>}
          media={media}
          state={state}
          link1={"https://youtube.com/shorts/h_VFaIXCF1M?feature=shared/"}
        />
        <Sots
          className={"odd"}
          idx1={6}
          sot1={<Box className="RealEstate"></Box>}
          media={media}
          state={state}
          link1={"https://t.me/EstateHomesBot"}
        />
        <Sots
          className={"even"}
          idx1={media ? 5 : 6}
          sot1={<Box className="ForEvents"></Box>}
          media={media}
          state={state}
          link1={"https://nfc-gcc.com/DATASOFT.pdf"}
        />

        <Sots
          className={"odd"}
          idx1={5}
          sot1={<Box className="RentACar"></Box>}
          media={media}
          state={state}
          link1={"https://nfc-gcc.com/RENTACARSMARTSYSTEM.pdf"}
        />
        <VectorUI media={media} className={"even"} />
        <ContactsModal media={media} state={state} />
        <VectorUI media={media} className={"even"} />
        <VectorUI media={media} className={"odd"} />
        <VectorUI media={media} className={"even"} />
        <VectorUI media={media} className={"odd"} />
        <VectorUI media={media} className={"even"} />
        <VectorUI media={media} className={"odd"} />
        <VectorUI media={media} className={"even"} />
        <VectorUI media={media} className={"odd"} />
        <VectorUI media={media} className={"even"} />
        <VectorUI media={media} className={"odd"} />
      </div>
    </Box>
  );
};

export default Content;
