import React from "react";
import { Box } from "@chakra-ui/react";

const VectorUI = ({ className }) => {
  const count = 10;
  const countArray = Array.from({ length: count }, (_, index) => index + 1);
  return (
    <div className={className}>
      {countArray.map((index) => (
        <div key={index} className="vector">
          <Box className="vectorsvg"></Box>
        </div>
      ))}
    </div>
  );
};

export default VectorUI;
