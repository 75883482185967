import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";

const Sots = ({ state, className, media, idx1, sot1, link1, idx2, sot2, link2 }) => {
  const count = 10;
  const countArray = Array.from({ length: count }, (_, index) => index + 1);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();

  const handleMouseEnter = () => {
    setTimeout(() => {
      setIsHovered(true);
      controls.start({ scale: 1.03 });
    }, 0);
    setTimeout(() => {
      setIsHovered(false);
      controls.start({ scale: 1 });
    }, 1500);
  };

  return (
    <div className={className}>
      {countArray.map((index) => (
        <div key={index} className="vector">
          {(index === idx1 || index === idx2) && state === 2 ? (
            <>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: isHovered ? 1.1 : 1 }}
                transition={{ duration: 0.5, ease: "anticipate" }}
                whileHover={isDesktop ? { scale: 1.03 } : {}}
                style={{ cursor: "pointer" }}
                onClick={handleMouseEnter}
              >
                {index === idx1 ? (
                  <a href={link1} target="_blank" rel="noopener noreferrer">
                    {sot1}
                  </a>
                ) : (
                  <a href={link2} target="_blank" rel="noopener noreferrer">
                    {sot2}
                  </a>
                )}
              </motion.div>
            </>
          ) : (
            <Box>
              <Box className="vectorsvg"></Box>
            </Box>
          )}
        </div>
      ))}
    </div>
  );
};


export default Sots;
