/* App.js */
import React, { useRef, useEffect, useState } from 'react';

import "./App.css";
import "./components/scroll/Scroll.css";
import Content from "./components/Content";

const App = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [showScrollbar, setShowScrollbar] = useState(true);
  const [dragging, setDragging] = useState(false);
  const contentRef = useRef(null);
  const scrollbarRef = useRef(null);
  const thumbRef = useRef(null);
  useEffect(() => {
    const handleContentScroll = () => {
      if (!dragging) {
        const maxOffsetY =
          scrollbarRef.current.clientHeight - thumbRef.current.clientHeight;
        const newPercentage =
          contentRef.current.scrollTop /
          (contentRef.current.scrollHeight - contentRef.current.clientHeight);
        const newThumbY = newPercentage * maxOffsetY;
        thumbRef.current.style.transform = `translateY(${newThumbY}px)`;
        setScrollPercentage(newPercentage);
      }
    };

    contentRef.current.addEventListener("scroll", handleContentScroll);
    updateScrollbarVisibility();
    window.addEventListener("resize", updateScrollbarVisibility);

    return () => {
      contentRef.current.removeEventListener("scroll", handleContentScroll);
      window.removeEventListener("resize", updateScrollbarVisibility);
    };
  }, [dragging]);

  useEffect(() => {
    updateScrollbarVisibility();
  }, [contentRef]);

  const updateScrollbarVisibility = () => {
    if (contentRef.current && scrollbarRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const screenHeight = window.innerHeight;
      const minScrollbarHeight = 50;

      scrollbarRef.current.style.minHeight = `${minScrollbarHeight}px`;

      setShowScrollbar(contentHeight > screenHeight);
    }
  };

  useEffect(() => {
    const handleWheelScroll = (e) => {
      if (contentRef.current) {
        contentRef.current.scrollTop += e.deltaY;
        const maxOffsetY =
          scrollbarRef.current.clientHeight - thumbRef.current.clientHeight;
        const newPercentage =
          contentRef.current.scrollTop /
          (contentRef.current.scrollHeight - contentRef.current.clientHeight);
        const newThumbY = newPercentage * maxOffsetY;
        thumbRef.current.style.transform = `translateY(${newThumbY}px)`;
        setScrollPercentage(newPercentage);
      }
    };

    contentRef.current.addEventListener("wheel", handleWheelScroll);

    return () => {
      contentRef.current.removeEventListener("wheel", handleWheelScroll);
    };
  }, []);

  const handleThumbDrag = (e) => {
    e.preventDefault();
    setDragging(true);
    const scrollbarRect = scrollbarRef.current.getBoundingClientRect();
    const thumbRect = thumbRef.current.getBoundingClientRect();
    const maxOffsetY = scrollbarRect.height - thumbRect.height;

    const offsetY = e.clientY - scrollbarRect.top - thumbRect.top;

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    function onMouseMove(e) {
      e.preventDefault();
      const newOffsetY = e.clientY - scrollbarRect.top - offsetY;
      const clampedOffsetY = Math.min(maxOffsetY, Math.max(0, newOffsetY));
      thumbRef.current.style.transform = `translateY(${clampedOffsetY}px)`;
      const newPercentage = clampedOffsetY / maxOffsetY;
      setScrollPercentage(newPercentage);
      contentRef.current.scrollTop =
        newPercentage *
        (contentRef.current.scrollHeight - contentRef.current.clientHeight);
    }

    function onMouseUp() {
      setDragging(false);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }
  };

  const handleScrollbarClick = (e) => {
    const scrollbarRect = scrollbarRef.current.getBoundingClientRect();
    const thumbRect = thumbRef.current.getBoundingClientRect();
    const clickY = e.clientY - scrollbarRect.top - thumbRect.height / 2;
    const maxOffsetY = scrollbarRect.height - thumbRect.height;
    const newThumbY = Math.min(
      maxOffsetY,
      Math.max(0, clickY - thumbRect.height / 2)
    );
    thumbRef.current.style.transform = `translateY(${newThumbY}px)`;
    const newPercentage = newThumbY / maxOffsetY;
    setScrollPercentage(newPercentage);
    contentRef.current.scrollTop =
      newPercentage *
      (contentRef.current.scrollHeight - contentRef.current.clientHeight);
  };
  return (
    <>
   <div className="scrollable-container">
      <div className="content" ref={contentRef}>
       <Content/>
      </div>
      {showScrollbar && (
        <div
          className="custom-scrollbar"
          ref={scrollbarRef}
          onClick={handleScrollbarClick}
        >
          <div
            className={`scroll-thumb ${dragging ? "dragging" : ""}`}
            onMouseDown={handleThumbDrag}
            ref={thumbRef}
          ></div>
        </div>
      )}
    </div>
    <div className='media-container'>
    <Content/>
    </div>
    </>

  );
};

export default App;
